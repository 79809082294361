import React from 'react';
import LogoWhite from '../assets/logo-white.svg';
import Card from './common/Card';
import Button from './common/Button';
import LaptopIcon from '../assets/laptop.svg';

function MobileNotSupported() {
  const handleClick = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  return (
    <div className="mobile-not-supported cyan-gradient-bg-image w-full h-full mx-auto">
      <div className="container mx-auto justify-center -translate-y-12 items-center flex-col flex h-full">
        <img src={LogoWhite} alt="Love wick logo" className="w-28 h-28 mb-4" />
        <Card>
          <img src={LaptopIcon} alt="Laptop icon" className="w-12 mx-auto mb-2" />
          <div className="text-center text-2xl font-bold">
            Please open this link on a computer
          </div>
          <p className="text-center text-base mt-2.5">
            We&apos;ve optimized the Lovewick on-demand date experience for bigger screens.
            Thanks for understanding!
          </p>
          <Button secondary onClick={handleClick} className="mt-7.5 w-full mx-auto">Copy link URL</Button>
        </Card>
      </div>
    </div>
  );
}

export default MobileNotSupported;
